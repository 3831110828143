import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const theme = {
  // 색상
  primary: '#111',
  black: '#111',
  white: '#fff',
  egb: '#26324d',
  mint: '#20a19c',
  purple: '#998ac5',
  error: '#ea4444',
  grayBg: '#f2f4f9',

  // 서비스 색상
  gray1: '#d2d2d2',
  gray2: '#707070',
  fluorescent: '#00e61f'
}

export default new Vuetify({
  theme: {
    themes: {
      light: theme
    }
  }
})
