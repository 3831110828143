import store from '@/store'

export default {
  methods: {
    initLoading() {
      store.commit('toggleLoading', true)

      setTimeout(() => {
        store.commit('toggleLoading', false)
      }, 1000)
    }
  }
}
