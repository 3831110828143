const state = {
  isNav: null,
  isToTop: false,
  isLoading: false
}

const getters = {}

const mutations = {
  toggleNav(state, payload) {
    state.isNav = payload
  },
  toggleLoading(state, payload) {
    state.isLoading = payload
  }
}

const actions = {}

export default { state, getters, mutations, actions }
