import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 라이브러리
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import VueMoment from 'vue-moment'
import VueDaumPostcode from 'vue-daum-postcode'

// 믹스인
import InitMixin from '@/lib/mixins/init.mixin'
import CommonMixin from '@/lib/mixins/common.mixin'

Vue.config.productionTip = false

// 전역 라이브러리 설정
Vue.use(VueCookies)
Vue.use(VueMoment)
Vue.use(VueDaumPostcode)

// 전역 믹스인 설정
Vue.mixin(InitMixin)
Vue.mixin(CommonMixin)

// 개발환경
console.log(store.state.tester.OMB)
console.log('-------------------')

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
