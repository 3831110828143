const state = {
  isAlert: false,
  alertType: 'JUST_ALERT',
  alertTitle: '',
  alertContent: '',
  cancelText: '',
  confirmText: '',
  confirmFunc: null
}

const getters = {}

const mutations = {
  setData(state, payload) {
    let copy = Object.assign(state, payload)
    state = copy
  }
}

const actions = {
  showAlert({ commit }, payload) {
    const isAlert = true
    const alertType = payload.alertType ? payload.alertType : 'JUST_ALERT'
    const alertTitle = payload.alertTitle ? payload.alertTitle : ''
    const alertContent = payload.alertContent ? payload.alertContent : ''
    const cancelText = payload.cancelText ? payload.cancelText : '취소'
    const confirmText = payload.confirmText ? payload.confirmText : '확인'
    const confirmFunc = payload.confirmFunc ? payload.confirmFunc : null

    return commit('setData', {
      isAlert,
      alertType,
      alertTitle,
      alertContent,
      cancelText,
      confirmText,
      confirmFunc
    })
  },
  closeAlert({ commit }, payload) {
    return commit('setData', { ...payload, isAlert: false })
  }
}

export default { namespaced: true, state, getters, mutations, actions }
