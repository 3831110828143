const state = {
  // 개발환경
  OMB: '2022-03-14 08:00:00'
}

const getters = {}

const mutations = {}

const actions = {}

export default { state, getters, mutations, actions }
