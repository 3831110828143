import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 스토어 모듈
import layout from '@/store/modules/layout'
import service from '@/store/modules/service'
import alert from '@/store/modules/alert'
import tester from '@/store/modules/tester'

export default new Vuex.Store({
  modules: { layout, service, alert, tester }
})
