import { mapActions } from 'vuex'

export default {
  methods: {
    // 알럿 설정
    ...mapActions({
      runShowAlert: 'alert/showAlert'
    }),
    showAlert(data) {
      const {
        alertType,
        alertTitle,
        alertContent,
        cancelText,
        confirmText,
        confirmFunc
      } = data

      let setData = {}
      setData.alertType = alertType
      setData.alertTitle = alertTitle
      setData.alertContent = alertContent
      setData.cancelText = cancelText
      setData.confirmText = confirmText
      setData.confirmFunc = confirmFunc

      this.runShowAlert(setData)
    }
  }
}
