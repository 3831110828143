<template>
  <Base />
</template>

<script>
export default {
  components: {
    Base: () => import('@/components/layout/Base')
  }
}
</script>
